import { createSlice } from '@reduxjs/toolkit';
import { findUserActivitiesAPI } from '../../../service/hackerthon/hackerthon.user.activity.service';
// utils

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  userActivities: [],
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: 'desc',
        property: 'createdAt',
      },
    ],
    filterBys: {

    }
  },
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUserActivities(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.userActivities = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setUserActivitySearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setUserActivitySearch } = slice.actions;
// ----------------------------------------------------------------------

export function getUserActivities() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { userActivity: { search } } = getState();

    const resp = await findUserActivitiesAPI({ ...search, value: `%${search.value}%` });

    if (resp.code === '200') dispatch(slice.actions.setUserActivities(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}