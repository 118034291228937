import { handleRequest } from '../../utils/axios';

export const findUserActivitiesAPI = async (data) => {
  const config = {
    url: '/user/activity/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const deleteUserActivityAPI = async (id) => {
  const config = {
    url: `/user/activity/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteUserActivitiesAPI = async (ids) => {
  const config = {
    url: `/user/activity/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
