import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import Image from './Image';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 50, height: 50, ...sx }}>
      <Image
        alt="login"
        src={`${process.env.PUBLIC_URL}/favicon/logo.png`}
      />
    </Box>
  );
}
