import { handleRequest } from '../../utils/axios';

export const findProjectAPI = async (data) => {
  const config = {
    url: '/project/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getProjectByIdAPI = async (id) => {
  const config = {
    url: `/project/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createProjectAPI = async (data) => {
  const config = {
    url: '/project/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updateProjectAPI = async (data) => {
  const config = {
    url: '/project/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deleteProjectAPI = async (id) => {
  const config = {
    url: `/project${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteProjectsAPI = async (ids) => {
  const config = {
    url: `/project/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
