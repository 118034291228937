import { Alert, AlertTitle, Container } from '@mui/material';
import PropTypes from 'prop-types';
import { useCurrentRole } from '../hooks/useAuth';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['ROLE_ADMIN', 'ROLE_EDITOR','ROLE_MANAGER']
  children: PropTypes.node
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRoles = useCurrentRole();
  const { translate } = useLocales();
  if (!accessibleRoles.some(r => currentRoles.includes(r))) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>{translate("message.permissionDeny")}</AlertTitle>
          {translate("message.permissionDenyDescription")}
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
