import { createSlice } from '@reduxjs/toolkit';
import { findSlidersAPI, getSliderByIdAPI } from '../../../service/hackerthon/hackerthon.slider.service';
// utils

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  sliders: [],
  slider: null,
  search: {
    page: 0,
    size: 10,
    value: '',
    orders: [
      {
        order: 'desc',
        property: 'id',
      },
    ],
    filterBys: {

    }
  },
};

const slice = createSlice({
  name: 'slider',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSliders(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.sliders = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setSlider(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.slider = response.data;
    },
    setSliderSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setSliderSearch } = slice.actions;
// ----------------------------------------------------------------------

export function getSliders() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    // read state from rootReducer
    const { slider } = getState();

    const resp = await findSlidersAPI({ ...slider.search, value: `%${slider.search.value}%` });

    if (resp.code === '200') dispatch(slice.actions.setSliders(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}


export function getSlider(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const resp = await getSliderByIdAPI(id);
    if (resp.code === '200') dispatch(slice.actions.setSlider(resp));
    else dispatch(slice.actions.hasError(resp));
  };
}
