import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import affiliateReducer from './slices/hackerthon/hackerthon.affiliate';
import answerReducer from './slices/hackerthon/hackerthon.answer';
import mediaCacheReducer from './slices/hackerthon/hackerthon.cache';
import examReducer from './slices/hackerthon/hackerthon.exam';
import exerciseReducer from './slices/hackerthon/hackerthon.exercise';
import practiceReducer from './slices/hackerthon/hackerthon.practice';
import mediaPrivilegeReducer from './slices/hackerthon/hackerthon.privilege';
import projectReducer from './slices/hackerthon/hackerthon.project';
import mediaRoleReducer from './slices/hackerthon/hackerthon.role';
import settingReducer from './slices/hackerthon/hackerthon.setting';
import sliderReducer from './slices/hackerthon/hackerthon.slider';
import solutionReducer from './slices/hackerthon/hackerthon.solution';
import tagReducer from './slices/hackerthon/hackerthon.tag';
import mediaUserReducer from './slices/hackerthon/hackerthon.user';
import userActivityReducer from './slices/hackerthon/hackerthon.user.activity';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  blacklist: ['error']
};

const rootReducer = combineReducers({
  mediaCache: persistReducer({ ...rootPersistConfig, key: "hackerthon-cache" }, mediaCacheReducer),
  mediaPrivilege: persistReducer({ ...rootPersistConfig, key: "hackerthon-privilege" }, mediaPrivilegeReducer),
  mediaRole: persistReducer({ ...rootPersistConfig, key: "hackerthon-role" }, mediaRoleReducer),
  mediaUser: persistReducer({ ...rootPersistConfig, key: "hackerthon-user" }, mediaUserReducer),
  exercise: persistReducer({ ...rootPersistConfig, key: "hackerthon-exercise" }, exerciseReducer),
  exam: persistReducer({ ...rootPersistConfig, key: "hackerthon-exam" }, examReducer),
  solution: persistReducer({ ...rootPersistConfig, key: "hackerthon-solution" }, solutionReducer),
  tag: persistReducer({ ...rootPersistConfig, key: "hackerthon-tag" }, tagReducer),
  slider: persistReducer({ ...rootPersistConfig, key: "hackerthon-slider" }, sliderReducer),
  userActivity: persistReducer({ ...rootPersistConfig, key: "hackerthon-userActivity" }, userActivityReducer),
  answer: persistReducer({ ...rootPersistConfig, key: "hackerthon-answer" }, answerReducer),
  practice: persistReducer({ ...rootPersistConfig, key: "hackerthon-practice" }, practiceReducer),
  project: persistReducer({ ...rootPersistConfig, key: "hackerthon-project" }, projectReducer),
  setting: persistReducer({ ...rootPersistConfig, key: 'hackerthon-setting' }, settingReducer),
  affiliate: persistReducer({ ...rootPersistConfig, key: 'hackerthon-affiliate' }, affiliateReducer)
});

export { rootPersistConfig, rootReducer };

