import { handleRequest } from '../../utils/axios';

export const findPracticeAPI = async (data) => {
  const config = {
    url: '/practice/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getPracticeByIdAPI = async (id) => {
  const config = {
    url: `/practice/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createPracticeAPI = async (data) => {
  const config = {
    url: '/practice/',
    method: 'POST',
    data
  };

  return handleRequest(config);
};

export const updatePracticeAPI = async (data) => {
  const config = {
    url: '/practice/',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const deletePracticeAPI = async (id) => {
  const config = {
    url: `/practice/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deletePracticesAPI = async (ids) => {
  const config = {
    url: `/practice/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
