import { handleRequest } from '../../utils/axios';
import { requestUploadImage } from '../uploadfile.service';

export const findAffiliatesAPI = async (data) => {
  const config = {
    url: '/affiliate/search',
    method: 'POST',
    data
  };
  return handleRequest(config);
};

export const getAffiliateByIdAPI = async (id) => {
  const config = {
    url: `/affiliate/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const createAffiliateAPI = async (data) => {
  const { fileObj, ...other } = data;
  let featureImage = null;

  const path = `affiliate/`;

  const resp = await requestUploadImage(fileObj, path);
  if (resp.code === '200')
    featureImage = resp.data;
  else return resp;

  const config = {
    url: '/affiliate/',
    method: 'POST',
    data: {
      ...other,
      featureImage
    }
  };

  return handleRequest(config);
};

export const updateAffiliateAPI = async (data) => {
  const { fileObj, ...other } = data;

  let featureImage = other?.featureImage || null;

  if (fileObj.name) {
    const path = `affiliate/`;

    const resp = await requestUploadImage(fileObj, path);
    if (resp.code === '200')
      featureImage = resp.data;
    else return resp;
  }

  const config = {
    url: '/affiliate/',
    method: 'PUT',
    data: {
      ...other,
      featureImage
    }
  };

  return handleRequest(config);
};

export const deleteAffiliateAPI = async (id) => {
  const config = {
    url: `/affiliate/${id}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};

export const deleteAffiliatesAPI = async (ids) => {
  const config = {
    url: `/affiliate/all/${ids.toString()}`,
    method: 'DELETE'
  };
  return handleRequest(config);
};
