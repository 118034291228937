import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';

// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// media
const ExerciseList = Loadable(lazy(() => import('../pages/hackerthon/exercise/ExerciseList')));
const ExerciseCreate = Loadable(lazy(() => import('../pages/hackerthon/exercise/ExerciseCreate')));

const MediaRoleCreate = Loadable(lazy(() => import('../pages/hackerthon/role/MediaRoleCreate')));
const MediaRoleList = Loadable(lazy(() => import('../pages/hackerthon/role/MediaRoleList')));

const ExamCreate = Loadable(lazy(() => import('../pages/hackerthon/exam/ExamCreate')));
const ExamList = Loadable(lazy(() => import('../pages/hackerthon/exam/ExamList')));

const TagCreate = Loadable(lazy(() => import('../pages/hackerthon/tag/TagCreate')));
const TagList = Loadable(lazy(() => import('../pages/hackerthon/tag/TagList')));

const SolutionCreate = Loadable(lazy(() => import('../pages/hackerthon/solution/SolutionCreate')));
const SolutionList = Loadable(lazy(() => import('../pages/hackerthon/solution/SolutionList')));

const SliderCreate = Loadable(lazy(() => import('../pages/hackerthon/slider/SliderCreate')));
const SliderList = Loadable(lazy(() => import('../pages/hackerthon/slider/SliderList')));

const MediaPrivilegeCreate = Loadable(lazy(() => import('../pages/hackerthon/privilege/MediaPrivilegeCreate')));
const MediaPrivilegeList = Loadable(lazy(() => import('../pages/hackerthon/privilege/MediaPrivilegeList')));

const PracticeCreate = Loadable(lazy(() => import('../pages/hackerthon/practice/PracticeCreate')));
const PracticeList = Loadable(lazy(() => import('../pages/hackerthon/practice/PracticeList')));

const ProjectCreate = Loadable(lazy(() => import('../pages/hackerthon/project/ProjectCreate')));
const ProjectList = Loadable(lazy(() => import('../pages/hackerthon/project/ProjectList')));

const ProjectSolutionCreate = Loadable(lazy(() => import('../pages/hackerthon/projectSolution/SolutionCreate')));
const ProjectSolutionList = Loadable(lazy(() => import('../pages/hackerthon/projectSolution/SolutionList')));

const UserList = Loadable(lazy(() => import('../pages/hackerthon/user/MediaUserList')));
const UserEdit = Loadable(lazy(() => import('../pages/hackerthon/user/MediaUserEdit')));

const MediaCacheList = Loadable(lazy(() => import('../pages/hackerthon/cache/MediaCacheList')));
const MediaCacheKeyList = Loadable(lazy(() => import('../pages/hackerthon/cache/MediaCacheKeyList')));

const UserActivityList = Loadable(lazy(() => import('../pages/hackerthon/userActivity/UserActivityList')));

const AnswerList = Loadable(lazy(() => import('../pages/hackerthon/answer/AnswerList')));
const SettingList = Loadable(lazy(() => import('../pages/hackerthon/setting/SettingList')));
const SettingCreate = Loadable(lazy(() => import('../pages/hackerthon/setting/SettingCreate')));

const AffiliateList = Loadable(lazy(() => import('../pages/hackerthon/affiliate/AffiliateList')));
const AffiliateCreate = Loadable(lazy(() => import('../pages/hackerthon/affiliate/AffiliateCreate')));

const hackerthonRoute = {
  path: 'hackerthon',
  element: (
    <RoleBasedGuard accessibleRoles={['ROLE_MANAGER', 'ROLE_EDITOR']}>
      <Outlet />
    </RoleBasedGuard>
  ),
  children: [
    { element: <Navigate to="/dashboard/hackerthon/exercises" replace />, index: true },

    // exercise
    { path: 'exercise/new', element: <ExerciseCreate /> },
    { path: 'exercises', element: <ExerciseList /> },
    { path: 'exercise/:id/edit', element: <ExerciseCreate /> },
    { path: 'exercise/:id/view', element: <ExerciseCreate /> },

    // exam
    { path: 'exam/new', element: <ExamCreate /> },
    { path: 'exams', element: <ExamList /> },
    { path: 'exam/:id/edit', element: <ExamCreate /> },
    { path: 'exam/:id/view', element: <ExamCreate /> },

    // tag
    { path: 'tag/new', element: <RoleBasedGuard accessibleRoles={['ROLE_MANAGER']}><TagCreate /></RoleBasedGuard> },
    { path: 'tags', element: <TagList /> },
    { path: 'tag/:id/edit', element: <RoleBasedGuard accessibleRoles={['ROLE_MANAGER']}><TagCreate /></RoleBasedGuard> },
    { path: 'tag/:id/view', element: <RoleBasedGuard accessibleRoles={['ROLE_MANAGER']}><TagCreate /></RoleBasedGuard> },

    // solution
    { path: 'solution/new', element: <SolutionCreate /> },
    { path: 'solutions', element: <SolutionList /> },
    { path: 'solution/:id/edit', element: <SolutionCreate /> },
    { path: 'solution/:id/view', element: <SolutionCreate /> },

    // solution
    { path: 'slider/new', element: <SliderCreate /> },
    { path: 'sliders', element: <SliderList /> },
    { path: 'slider/:id/edit', element: <SliderCreate /> },
    { path: 'slider/:id/view', element: <SliderCreate /> },

    // role
    { path: 'role/new', element: <MediaRoleCreate /> },
    { path: 'roles', element: <MediaRoleList /> },
    { path: 'role/:id/edit', element: <MediaRoleCreate /> },
    { path: 'role/:id/view', element: <MediaRoleCreate /> },

    // privilege
    { path: 'privilege/new', element: <MediaPrivilegeCreate /> },
    { path: 'privileges', element: <MediaPrivilegeList /> },
    { path: 'privilege/:id/edit', element: <MediaPrivilegeCreate /> },
    { path: 'privilege/:id/view', element: <MediaPrivilegeCreate /> },

    // problem
    { path: 'practice/new', element: <PracticeCreate /> },
    { path: 'practices', element: <PracticeList /> },
    { path: 'practice/:id/edit', element: <PracticeCreate /> },
    { path: 'practice/:id/view', element: <PracticeCreate /> },

    // problem
    { path: 'project/new', element: <ProjectCreate /> },
    { path: 'projects', element: <ProjectList /> },
    { path: 'project/:id/edit', element: <ProjectCreate /> },
    { path: 'project/:id/view', element: <ProjectCreate /> },

    // solution
    { path: 'project-solution/new', element: <ProjectSolutionCreate /> },
    { path: 'project-solutions', element: <ProjectSolutionList /> },
    { path: 'project-solution/:id/edit', element: <ProjectSolutionCreate /> },
    { path: 'project-solution/:id/view', element: <ProjectSolutionCreate /> },

    // answer
    { path: 'answers', element: <AnswerList /> },

    // user
    { path: 'users', element: <UserList /> },
    { path: 'user/:id/view', element: <UserEdit /> },
    { path: 'user/:id/edit/role', element: <UserEdit /> },
    { path: 'user/:id/edit/email', element: <UserEdit /> },
    { path: 'user/:id/edit/phone', element: <UserEdit /> },
    { path: 'user/:id/edit/status', element: <UserEdit /> },
    { path: 'user/:id/edit/uid', element: <UserEdit /> },
    { path: 'user/:id/edit/info', element: <UserEdit /> },

    // user activity
    { path: 'user/activities', element: <UserActivityList /> },

    // cache
    { path: 'caches', element: <MediaCacheList /> },
    { path: 'cache/:name/keys', element: <MediaCacheKeyList /> },

    { path: 'setting/new', element: <SettingCreate /> },
    { path: 'settings', element: <SettingList /> },
    { path: 'setting/:id/edit', element: <SettingCreate /> },
    { path: 'setting/:id/view', element: <SettingCreate /> },

    { element: <Navigate to="/dashboard/hackerthon/affiliates" replace />, index: true },

    // affiliate
    { path: 'affiliate/new', element: <AffiliateCreate /> },
    { path: 'affiliates', element: <AffiliateList /> },
    { path: 'affiliate/:id/edit', element: <AffiliateCreate /> },
    { path: 'affiliate/:id/view', element: <AffiliateCreate /> },
  ],
};

export default hackerthonRoute;
