// routes
import { PATH_BLOGS, PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle src={`${process.env.PUBLIC_URL}/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const ICONS = {
  setting: getIcon('ic_cache'),
  exercise: getIcon('ic_booking'),
  user: getIcon('ic_user'),
  analytics: getIcon('ic_dashboard'),
  project: getIcon('ic_project'),
  other: getIcon('ic_other'),
  blog: getIcon('ic_blog'),
  shop: getIcon('ic_cart'),
};
const navConfig = [
  // GENERAL
  {
    subheader: 'menu.general',
    items: [
      {
        title: 'menu.analytics',
        path: PATH_DASHBOARD.analytics, icon: ICONS.analytics,
        hasRoles: ["ROLE_ADMIN", 'ROLE_MANAGER', 'ROLE_EDITOR']
      }
    ],
  },
  // MANAGEMENT
  {
    subheader: 'menu.hackerthon',
    items: [
      {
        title: 'menu.hackerthon',
        path: PATH_DASHBOARD.hackerthon.exercises,
        icon: ICONS.exercise,
        hasRoles: ["ROLE_MANAGER", "ROLE_EDITOR"],
        children: [
          { title: 'menu.exercise', path: PATH_DASHBOARD.hackerthon.exercises, hasRoles: ["ROLE_MANAGER", "ROLE_EDITOR"] },
          // { title: 'menu.exam', path: PATH_DASHBOARD.hackerthon.exams, hasRoles: ["ROLE_ADMIN", "ROLE_MANAGER"] },
          { title: 'menu.answer', path: PATH_DASHBOARD.hackerthon.answers, hasRoles: ["ROLE_MANAGER"] },
          { title: 'menu.solution', path: PATH_DASHBOARD.hackerthon.solutions, hasRoles: ["ROLE_MANAGER"] },
          { title: 'menu.tag', path: PATH_DASHBOARD.hackerthon.tags, hasRoles: ["ROLE_EDITOR"] },
        ],
      },
      {
        title: 'menu.project',
        path: PATH_DASHBOARD.hackerthon.practices,
        icon: ICONS.project,
        hasRoles: ["ROLE_MANAGER", "ROLE_EDITOR"],
        children: [
          { title: 'menu.practice', path: PATH_DASHBOARD.hackerthon.practices, hasRoles: ["ROLE_MANAGER", "ROLE_EDITOR"] },
          { title: 'menu.project', path: PATH_DASHBOARD.hackerthon.projects, hasRoles: ["ROLE_MANAGER", "ROLE_EDITOR"] },
          { title: 'menu.solution', path: PATH_DASHBOARD.hackerthon.projectSolutions, hasRoles: ["ROLE_MANAGER"] },
        ],
      },
      {
        title: 'menu.other',
        path: PATH_DASHBOARD.hackerthon.tags,
        icon: ICONS.other,
        hasRoles: ["ROLE_MANAGER"],
        children: [
          { title: 'menu.tag', path: PATH_DASHBOARD.hackerthon.tags },
          { title: 'menu.comment', path: PATH_BLOGS },
        ],
      },
      {
        title: 'menu.shop',
        path: '',
        icon: ICONS.shop,
        hasRoles: ["ROLE_MANAGER"],
        children: [
          { title: 'menu.affiliate', path: PATH_DASHBOARD.hackerthon.affiliates },
        ],
      }
    ],
  },
  {
    subheader: 'menu.blog',
    items: [
      {
        title: 'menu.blog',
        path: PATH_BLOGS,
        icon: ICONS.blog,
        hasRoles: ["ROLE_MANAGER"],
      }
    ],
  },
  {
    subheader: 'menu.user',
    items: [
      {
        title: 'menu.user',
        path: PATH_DASHBOARD.hackerthon.users,
        icon: ICONS.user,
        hasRoles: ["ROLE_ADMIN"],
        children: [
          { title: 'menu.user', path: PATH_DASHBOARD.hackerthon.users, hasRoles: ["ROLE_ADMIN"] },
          { title: 'menu.privilege', path: PATH_DASHBOARD.hackerthon.privileges, hasRoles: ["ROLE_ADMIN"] },
          { title: 'menu.role', path: PATH_DASHBOARD.hackerthon.roles, hasRoles: ["ROLE_ADMIN"] },
          { title: 'menu.userActivity', path: PATH_DASHBOARD.hackerthon.userActivities, hasRoles: ["ROLE_ADMIN"] },
        ],
      }
    ],
  },
  {
    subheader: 'menu.setting',
    items: [
      {
        title: 'menu.setting',
        path: PATH_DASHBOARD.hackerthon.caches,
        icon: ICONS.setting,
        hasRoles: ["ROLE_ADMIN"],
        children: [
          { title: 'menu.cache', path: PATH_DASHBOARD.hackerthon.caches, hasRoles: ["ROLE_ADMIN"] },
          { title: 'menu.slider', path: PATH_DASHBOARD.hackerthon.sliders, hasRoles: ["ROLE_ADMIN"] },
          { title: 'menu.setting', path: PATH_DASHBOARD.hackerthon.settings },
        ],
      }
    ],
  },
];
export default navConfig;
