function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  profile: path(ROOTS_DASHBOARD, '/profile'),
  analytics: path(ROOTS_DASHBOARD, '/analytics'),

  hackerthon: {
    root: path(ROOTS_DASHBOARD, '/hackerthon'),

    newRole: path(ROOTS_DASHBOARD, '/hackerthon/role/new'),
    roles: path(ROOTS_DASHBOARD, '/hackerthon/roles'),

    newExam: path(ROOTS_DASHBOARD, '/hackerthon/exam/new'),
    exams: path(ROOTS_DASHBOARD, '/hackerthon/exams'),

    newTag: path(ROOTS_DASHBOARD, '/hackerthon/tag/new'),
    tags: path(ROOTS_DASHBOARD, '/hackerthon/tags'),

    newExercise: path(ROOTS_DASHBOARD, '/hackerthon/exercise/new'),
    exercises: path(ROOTS_DASHBOARD, '/hackerthon/exercises'),

    newExamExercise: path(ROOTS_DASHBOARD, '/hackerthon/examexercise/new'),
    examExercises: path(ROOTS_DASHBOARD, '/hackerthon/examexercises'),

    newPrivilege: path(ROOTS_DASHBOARD, '/hackerthon/privilege/new'),
    privileges: path(ROOTS_DASHBOARD, '/hackerthon/privileges'),

    users: path(ROOTS_DASHBOARD, '/hackerthon/users'),

    caches: path(ROOTS_DASHBOARD, '/hackerthon/caches'),

    newSolution: path(ROOTS_DASHBOARD, '/hackerthon/solution/new'),
    solutions: path(ROOTS_DASHBOARD, '/hackerthon/solutions'),

    newSlider: path(ROOTS_DASHBOARD, '/hackerthon/slider/new'),
    sliders: path(ROOTS_DASHBOARD, '/hackerthon/sliders'),

    newAnswer: path(ROOTS_DASHBOARD, '/hackerthon/answer/new'),
    answers: path(ROOTS_DASHBOARD, '/hackerthon/answers'),

    newPractice: path(ROOTS_DASHBOARD, '/hackerthon/practice/new'),
    practices: path(ROOTS_DASHBOARD, '/hackerthon/practices'),

    newProject: path(ROOTS_DASHBOARD, '/hackerthon/project/new'),
    projects: path(ROOTS_DASHBOARD, '/hackerthon/projects'),

    newProjectSolution: path(ROOTS_DASHBOARD, '/hackerthon/project-solution/new'),
    projectSolutions: path(ROOTS_DASHBOARD, '/hackerthon/project-solutions'),

    userActivities: path(ROOTS_DASHBOARD, '/hackerthon/user/activities'),

    newSetting: path(ROOTS_DASHBOARD, '/hackerthon/setting/new'),
    settings: path(ROOTS_DASHBOARD, '/hackerthon/settings'),

    newAffiliate: path(ROOTS_DASHBOARD, '/hackerthon/affiliate/new'),
    affiliates: path(ROOTS_DASHBOARD, '/hackerthon/affiliates')
  }
};

export const PATH_DOCS = 'https://hackerthon.net';
export const PATH_BLOGS = 'https://blog.jmaster.io';
